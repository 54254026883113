import React from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import { createPrimaryButton } from "../HelperComponents";

import "./footer.scss";

function Footer() {
  return (
    <div>
      <div className="footer-body">
        <Container>
          <Row>
            <Col lg={4}>
              <h3>
                <b>Survey</b>
              </h3>
              <p>
                How helpful is this website? Was it easy to use? Please take our
                2-minute survey to give us some feedback and help us improve.
              </p>
              {createPrimaryButton(
                "Take Survey",
                "https://survey.zohopublic.com/zs/zDb8fI",
                "_blank"
              )}
            </Col>
            <Col lg={4}>
              <p>
                <a href="/about">About</a>
              </p>
              <p>
                <a href="/map">Site Map</a>
              </p>
              <p>
                <a href="/resources">Resources</a>
              </p>
            </Col>
            <Col lg={4}>
              <p>
                <a href="/affiliate">Affiliate Disclaimer</a>
              </p>
              <p>
                <a href="/privacy">Privacy Policy</a>
              </p>
              <p>
                <a href="/terms">Terms and Conditions</a>
              </p>
              <p>
                <a href="https://servantcreations.com">
                  A ServantCreations website
                </a>
              </p>
            </Col>
          </Row>
        </Container>
      </div>
      <div className="footer-copyright">
        <Container>
          <span className="text-muted">© 2021 RaisedBedDesigner.com</span>
        </Container>
      </div>
    </div>
  );
}

export default Footer;
