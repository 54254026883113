import React from "react";
import Container from "react-bootstrap/Container";

function Resources() {
  return (
    <Container className="content">
      <h1>Resources</h1>
      <p>
        We only refer you to resources that we have personally used, researched,
        and approve of.
      </p>
      <h2>Gardening Books</h2>
      <p>Coming soon...</p>
      <h2>Where to Buy Garden Supplies</h2>
      <p>Coming soon...</p>
      <h2>Gardening Websites</h2>
      <ul>
        <li>
          <span>
            <a href="https://journeywithjill.net">JourneyWithJill.net</a> - Jill
            has a gardening website great for beginner gardeners. Tons of great
            articles and resources for starting and maintaining your garden.
            Jill also has a course on Teachable called{" "}
            <a href="https://beginnersgardenshortcut.teachable.com/courses/raised-bed-mini-bundle?affcode=176643_p01sds2m">
              Raised Bed Mini Bundle
            </a>
            for people who are interested in building their own raised garden
            bed.
          </span>
        </li>
        <li>
          <span>
            <a href="https://melissaknorris.com/">MelissaKNorris.com</a> -
            Melissa has a gardening and homesteading website. Offers a family
            garden plan for growing your own fruits and vegetables that we have
            used and recommend.
          </span>
        </li>
      </ul>
    </Container>
  );
}

export default Resources;
