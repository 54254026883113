import { Button } from "react-bootstrap";

export function createPrimaryButton(
  displayText: string,
  href: string,
  target: string = "_self"
) {
  return (
    <Button
      className="custom-primary-btn"
      size="lg"
      href={href}
      target={target}
    >
      {displayText}
      <div className="btn-primary-bottom-border"></div>
    </Button>
  );
}
