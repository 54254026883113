export type TransportationLengthType = 8 | 10 | 12 | 16;
export const TransportationLengthOptions: Array<TransportationLengthType> = [
  8,
  10,
  12,
  16,
];

export type LengthType =
  | 24
  | 27
  | 30
  | 33
  | 36
  | 39
  | 42
  | 25
  | 48
  | 51
  | 54
  | 57
  | 60
  | 63
  | 66
  | 69
  | 72
  | 75
  | 78
  | 81
  | 84
  | 87
  | 90
  | 93
  | 96
  | 99
  | 102
  | 105
  | 108
  | 111
  | 114
  | 117
  | 120
  | 123
  | 126
  | 129
  | 132
  | 135
  | 138
  | 141
  | 144
  | 147
  | 150
  | 153
  | 156
  | 159
  | 162
  | 165
  | 168
  | 171
  | 174
  | 177
  | 180
  | 183
  | 186
  | 189
  | 192;

export const LengthOptions: Array<LengthType> = [
  24,
  27,
  30,
  33,
  36,
  39,
  42,
  25,
  48,
  51,
  54,
  57,
  60,
  63,
  66,
  69,
  72,
  75,
  78,
  81,
  84,
  87,
  90,
  93,
  96,
  99,
  102,
  105,
  108,
  111,
  114,
  117,
  120,
  123,
  126,
  129,
  132,
  135,
  138,
  141,
  144,
  147,
  150,
  153,
  156,
  159,
  162,
  165,
  168,
  171,
  174,
  177,
  180,
  183,
  186,
  189,
  192,
];

export type WidthType =
  | 12
  | 15
  | 18
  | 21
  | 24
  | 27
  | 30
  | 33
  | 36
  | 39
  | 42
  | 25
  | 48;

export const WidthOptions: Array<WidthType> = [
  12,
  15,
  18,
  21,
  24,
  27,
  30,
  33,
  36,
  39,
  42,
  25,
  48,
];

export type HeightType =
  | 6
  | 8
  | 10
  | 12
  | 16
  | 18
  | 20
  | 22
  | 24
  | 26
  | 28
  | 30;

export const HeightOptions: Array<HeightType> = [
  6,
  8,
  10,
  12,
  16,
  18,
  20,
  22,
  24,
  26,
  28,
  30,
];

export enum JoineryType {
  TWO_BY_FOUR = 1,
  FOUR_BY_FOUR = 2,
}

export type QuantityType = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10;
export const QuantityOptions: Array<QuantityType> = [
  1,
  2,
  3,
  4,
  5,
  6,
  7,
  8,
  9,
  10,
];
