import style_classic from "../../../assets/images/styles/classic/classic.jpg";
import style_landscaper from "../../../assets/images/styles/landscaper/landscaper.jpg";
import style_four_by_four from "../../../assets/images/styles/four-by-four/four-by-four.jpg";

export type StyleData = {
  title: string;
  image: string;
  description: string;
  difficulty: number;
  strength: number;
  materialCost: number;
  comingSoon: boolean;
  link: string;
};

export const classicStyleData = {
  title: "The Classic",
  image: style_classic,
  description:
    "Simple but strong. Built with standard sized construction lumber found at any big box store. Especially suited for vegetable gardening.",
  difficulty: 2,
  strength: 5,
  materialCost: 2,
  comingSoon: false,
  link: "/design/classic",
};

const Styles: Array<StyleData> = [
  classicStyleData,
  {
    title: "The Landscaper",
    image: style_landscaper,
    description:
      "Built with treated landscaping timbers. Ideal for flower beds or decorative gardens. Not well suited for vegetable gardens due to treated lumber.",
    difficulty: 2,
    strength: 4,
    materialCost: 2,
    comingSoon: true,
    link: "design/landscaper",
  },
  {
    title: "The 4x4",
    image: style_four_by_four,
    description:
      "Built exclusively with 4x4 lumber. Ideal for any purpose. Looks amazing!",
    difficulty: 2,
    strength: 4,
    materialCost: 2,
    comingSoon: true,
    link: "design/4x4",
  },
];

export { Styles };
