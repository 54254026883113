import React from "react";

const ClassicPlan: React.FC = (props) => {
  // @ts-ignore
  const params = new URLSearchParams(props.location.search);
  console.log(params.get("obj"));

  const rawObject = params.get("obj");

  if (!rawObject) {
    return <div>Error!</div>;
  }

  if (rawObject) {
    console.log(JSON.parse(rawObject));
  }
  // alert(JSON.parse(location.search));
  return (
    <div>
      <h1>The Classic - Plan</h1>
      <p>
        You did it! Your plan for "The Classic" raised garden bed is complete
        and contains everything you need to get building.
      </p>
      <h4>Diagram</h4>
      <h4>Materials</h4>
      <h4>Tools</h4>
      <ul>
        <li>hand saw (or power saw)</li>
        <li>cordless screwdriver</li>
        <li>shovel</li>
        <li>wheelbarrow</li>
      </ul>
      <h4>Cuts List</h4>
      <h4>Instructions</h4>
    </div>
  );
};

export default ClassicPlan;
