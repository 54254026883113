import "./App.scss";

import NavBar from "../NavBar/NavBar";
import React from "react";
import Routes from "../Routes/Routes";
import { Container } from "react-bootstrap";
import Footer from "../Footer/Footer";

const isDev = !process.env.NODE_ENV || process.env.NODE_ENV === "development";

if (!isDev) {
  // turn on/off any dev specific settings...
  // plausible analytics here?
}

function App() {
  return (
    <div className="app">
      <NavBar />
      <Container className="content-area">
        <Routes />
      </Container>
      <Footer />
    </div>
  );
}

export default App;
