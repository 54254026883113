import React from "react";
import Container from "react-bootstrap/Container";

function Affiliate() {
  return (
    <Container className="content">
      <h1>Affiliate Links Disclaimer</h1>
      <p>Disclaimer coming soon once affiliate links are on this site...</p>
    </Container>
  );
}

export default Affiliate;
