import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import PrivacyPanel from "../Pages/PrivacyPanel/PrivacyPanel";
import About from "../Pages/About/About";
import TermsAndConditionsPanel from "../Pages/TermsAndConditionsPanel/TermsAndConditionsPanel";
import SiteMap from "../Pages/SiteMap/SiteMap";
import Home from "../Pages/Home/Home";
import Resources from "../Pages/Resources/Resources";
import Affliate from "../Pages/Affiliate/Affiliate";
import Design from "../Pages/Design/Design";
import Classic from "../Pages/Design/Classic/Classic";
import Plans from "../Pages/Plans/Plans";
import ClassicPlan from "../Pages/Plan/ClassicPlan";

// import history from "../../util/history";

function Routes() {
  return (
    <Router>
      <Switch>
        <Route exact path="/" component={Home} />
        <Route exact path="/design" component={Design} />
        <Route exact path="/design/classic" component={Classic} />
        <Route exact path="/plan/classic" component={ClassicPlan} />
        <Route exact path="/plans" component={Plans} />
        <Route exact path="/resources" component={Resources} />
        <Route exact path="/about" component={About} />
        <Route exact path="/privacy" component={PrivacyPanel} />
        <Route exact path="/terms" component={TermsAndConditionsPanel} />
        <Route exact path="/map" component={SiteMap} />
        <Route exact path="/affiliate" component={Affliate} />
        <Redirect to="/" />
      </Switch>
    </Router>
  );
}

export default Routes;
