import React from "react";
import Container from "react-bootstrap/Container";

function About() {
  return (
    <Container className="content">
      <h1>About Raised Bed Designer</h1>
      <p>Todo...</p>

      <h2>Site Updates</h2>
      <ul>
        <li>
          <span>
            <b>March 2021</b> - First version...
          </span>
        </li>
      </ul>

      <h2>Servant Creation</h2>
      <p>
        SoilCalculator.com is owned and operated by{" "}
        <a href="https://servantcreations.com">Servant Creations</a> a small USA
        based company dedicated to serving the Lord Jesus Christ through
        building websites and web products that will bless others.
      </p>
    </Container>
  );
}

export default About;
