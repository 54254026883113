import React, { useState } from "react";
import { Button, Card, CardDeck } from "react-bootstrap";
import {
  TransportationLengthType,
  LengthType,
  WidthType,
  HeightType,
} from "../../Constants/Constants";
import "./design.scss";

import { Styles } from "./styleData";

const Design: React.FC = () => {
  function createStyleCard(styleData: any) {
    return (
      <Card className="style-card">
        <Card.Img
          className="style-card-image"
          variant="top"
          src={styleData.image}
        />
        <Card.Body>
          <Card.Title>{styleData.title}</Card.Title>
          <Card.Text>{styleData.description}</Card.Text>
          {styleData.comingSoon && (
            <h6 className="text-primary">COMING SOON!</h6>
          )}
          {!styleData.comingSoon && (
            <Button variant="secondary" href={styleData.link}>
              Start Designing
              <div>test</div>
            </Button>
          )}
        </Card.Body>
      </Card>
    );
  }

  return (
    <div>
      <h1>Choose Your Style</h1>
      <p className="mt-n2">
        The Raised Bed Designer helps you design and build your very own raised
        garden beds. Pick a style to get started.
      </p>
      <CardDeck>
        {Styles.map((styleData: any) => {
          return createStyleCard(styleData);
        })}
      </CardDeck>
    </div>
  );
};

export default Design;
