import React, { useState } from "react";
import {
  Button,
  CardDeck,
  Form,
  DropdownButton,
  Dropdown,
  Container,
  Row,
  Col,
  Image,
  Accordion,
  Card,
  Alert,
} from "react-bootstrap";
import { ClassicModel } from "../../../../domain/RaisedBedModels";
import {
  TransportationLengthType,
  LengthType,
  LengthOptions,
  WidthType,
  WidthOptions,
  HeightType,
  HeightOptions,
  QuantityType,
  QuantityOptions,
  JoineryType,
} from "../../../Constants/Constants";
import { useHistory } from "react-router-dom";
import classicImg1 from "../../../../assets/images/styles/classic/classic raised garden bed newly created.jpg";

import { classicStyleData } from "../styleData";
// import history from "../../../../util/history";

const Classic: React.FC = () => {
  const history = useHistory();
  enum Steps {
    INTRO = 1,
    TRANSPORTATION = 2,
    HEIGHT = 3,
    LENGTH_WIDTH = 4,
    JOINERY = 5,
    QUANTITY = 6,
    FINISH = 7,
  }

  const [
    transportationMax,
    setTransportationMax,
  ] = useState<TransportationLengthType>(8);
  const [length, setLength] = useState<LengthType>(84);
  const [width, setWidth] = useState<WidthType>(24);
  const [height, setHeight] = useState<HeightType>(20);
  const [step, setStep] = useState<Steps>(Steps.INTRO);
  const [usePopularDimensions, setUsePopularDimensions] = useState<boolean>(
    false
  );
  const [joineryType, setJoineryType] = useState<JoineryType>(
    JoineryType.FOUR_BY_FOUR
  );
  const [quantity, setQuantity] = useState<QuantityType>(1);

  function handleNextButton(nextStep: Steps) {
    if (nextStep === Steps.FINISH) {
      const model = new ClassicModel(
        transportationMax,
        height,
        length,
        width,
        joineryType,
        quantity
      );
      history.push("/plan/classic?obj=" + JSON.stringify([model]));
    } else {
      setStep(nextStep);
    }
  }

  function getNextButtonText(nextStep: Steps, previousStep?: Steps): string {
    if (nextStep === Steps.FINISH) {
      return "Finish";
    }

    if (!previousStep) {
      return "Start";
    }

    return "Next";
  }

  function displayButtons(nextStep: Steps, previousStep?: Steps) {
    return (
      <div className="mt-4">
        <hr />
        {previousStep && (
          <Button
            variant="secondary"
            className="mr-2"
            size="lg"
            onClick={() => setStep(previousStep)}
          >
            Previous
          </Button>
        )}
        <Button
          variant="secondary"
          size="lg"
          onClick={() => handleNextButton(nextStep)}
        >
          {getNextButtonText(nextStep, previousStep)}
        </Button>
      </div>
    );
  }

  function handleTransportationSelection(
    event: React.ChangeEvent<HTMLInputElement>
  ) {
    const value: TransportationLengthType = Number(
      event.target.value
    ) as TransportationLengthType;
    setTransportationMax(value);
  }

  function displayTransportation() {
    return (
      <div>
        <h5>How long of lumber can your vehicle transport?</h5>
        <Form.Group>
          <Form.Check
            checked={transportationMax === 8}
            type="radio"
            label="8 feet"
            id="transportation8"
            value={8}
            onChange={handleTransportationSelection}
            name="transportationRadio"
          />
          <Form.Check
            checked={transportationMax === 10}
            type="radio"
            label="10 feet"
            id="transportation10"
            value={10}
            onChange={handleTransportationSelection}
            name="transportationRadio"
          />
          <Form.Check
            checked={transportationMax === 12}
            type="radio"
            label="12 feet"
            id="transportation12"
            value={12}
            onChange={handleTransportationSelection}
            name="transportationRadio"
          />
          <Form.Check
            checked={transportationMax === 16}
            type="radio"
            label="16 feet"
            id="transportation16"
            value={16}
            onChange={handleTransportationSelection}
            name="transportationRadio"
          />
        </Form.Group>
        <Alert variant={"info"}>
          TIP: You need to measure your vehicle to be absolutely sure!
        </Alert>
        <Accordion>
          <Card>
            <Accordion.Toggle as={Card.Header} variant="link" eventKey="0">
              How do I find out?
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="0">
              <Card.Body>
                <p>
                  You <i>need</i> to measure your vehicle. You <i>do not</i>{" "}
                  want to purchase lumber only to find out in the parking lot
                  that it doesn't fit in your vehicle (yes, this happens).
                </p>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
          <Card>
            <Accordion.Toggle as={Card.Header} variant="link" eventKey="1">
              See vehicle guidelines.
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="1">
              <Card.Body>
                <p>
                  <b>Sedans - </b> Smaller sedans will likely be unable to
                  transport lumber. Medium sized and larger sedans can typically
                  transport a few 8' lumber boards if the seats are folded just
                  right. If you can fold down the seat and extend the lumber
                  into the trunk that helps too. Keep in mind that wood
                  splinters. Consider laying down a blanket or tarp to protect
                  your seats.
                </p>
                <p>
                  <b>Minivans and Smaller SUVs - </b>Can typically transport
                  lumber as long as 10'. Some will transport 12' long lumber.
                  Will likely need to fold down seats or have lumber sticking
                  out back hatch window. Keep in mind that lumber is heavy if
                  you are resting lumber on the hatch frame.
                </p>
                <p>
                  <b>Trucks, Vans, and Utility Trailers - </b> If you have one
                  of these you already know your limits. Just remember to strap
                  down any lumber!
                </p>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        </Accordion>

        {displayButtons(Steps.HEIGHT, Steps.INTRO)}
      </div>
    );
  }

  function getLevels(height: HeightType): Array<HeightType> {
    switch (height.valueOf()) {
      case 6:
      case 8:
      case 10:
      case 12: {
        return [height];
      }
      case 16: {
        return [8, 8];
      }
      case 18: {
        return [8, 10];
      }
      case 20: {
        return [10, 10];
      }
      case 22: {
        return [10, 12];
      }
      case 24: {
        return [12, 12];
      }
      case 26: {
        return [6, 10, 10];
      }
      case 28: {
        return [8, 10, 10];
      }
      case 30: {
        return [10, 10, 10];
      }
      default: {
        return [];
      }
    }
  }

  function drawLevel(height: HeightType) {
    return (
      <div className="mb-1">
        <svg width="60%" height={height * 5}>
          <rect
            width="100%"
            height={(height * 5).toString()}
            style={{
              fill: "tan",
              stroke: "brown",
            }}
          />
          <text
            x="50%"
            y="50%"
            width="100%"
            height={height * 5}
            font-family="Verdana"
            font-size="22"
            fill="black"
            textAnchor="middle"
            dominantBaseline="middle"
          >
            {height + `"`}
          </text>
        </svg>
      </div>
    );
  }

  function drawPlan(height: HeightType, length: LengthType, width: WidthType) {
    let levels: Array<HeightType> = getLevels(height);

    let levelsLength: number = 0;
    levels.forEach((level: HeightType) => {
      levelsLength += level;
    });

    let svgHeight: number = levelsLength + width + levelsLength;
    let svgWidth: number = levelsLength + length + levelsLength;

    const lengthStartingX = levelsLength;
    let lengthCurrentY = 0;

    const widthStartingY = levelsLength;
    let widthCurrentX = 0;

    return (
      <div>
        <svg width={svgWidth} height={svgHeight}>
          {/* <rect width="100%" height="100%" fill="green" /> */}
          {levels.map((level, index) => {
            const currentX = widthCurrentX;
            widthCurrentX += level;

            const currentY = lengthCurrentY;
            lengthCurrentY += level;

            return (
              <>
                <rect
                  y={widthStartingY}
                  x={currentX}
                  width={level}
                  height={width}
                  style={{
                    fill: "tan",
                    stroke: "brown",
                  }}
                />
                <rect
                  y={currentY}
                  x={lengthStartingX}
                  width={length}
                  height={level}
                  style={{
                    fill: "tan",
                    stroke: "brown",
                  }}
                />
              </>
            );
          })}
          {levels.reverse().map((level, index) => {
            const currentX = widthCurrentX;
            widthCurrentX += level;

            const currentY = lengthCurrentY;
            lengthCurrentY += level;

            return (
              <>
                <rect
                  y={widthStartingY}
                  x={currentX + length}
                  width={level}
                  height={width}
                  style={{
                    fill: "tan",
                    stroke: "brown",
                  }}
                />
                <rect
                  y={currentY + width}
                  x={lengthStartingX}
                  width={length}
                  height={level}
                  style={{
                    fill: "tan",
                    stroke: "brown",
                  }}
                />
              </>
            );
          })}
        </svg>
      </div>
    );
  }

  function drawDimensions(length: LengthType, width: WidthType) {
    const multiplier = 2;
    return (
      <div className="mb-2">
        <span>Your raised garden bed will have this shape:</span>
        {drawPlan(height, length, width)}
        {/* <svg width="100%" height={width * multiplier}>
          <rect
            width={length * multiplier}
            height={width * multiplier}
            style={{
              fill: "tan",
              stroke: "brown",
            }}
          />
        </svg> */}
      </div>
    );
  }

  function drawLevels(height: HeightType) {
    let levels: Array<HeightType> = getLevels(height);
    return (
      <div>
        <span>
          Your raised garden bed will have {levels.length}{" "}
          {levels.length > 1 ? "levels" : "level"}:
        </span>
        {levels.map((level) => {
          return drawLevel(level);
        })}
      </div>
    );
  }

  function displayHeight() {
    return (
      <div>
        <h5>Height</h5>
        <DropdownButton
          className="mb-2"
          variant="secondary"
          size="lg"
          id="height"
          title={height + '"'}
          onSelect={(eventKey: any, event: Object) => {
            setHeight(Number(eventKey) as HeightType);
          }}
        >
          {HeightOptions.map((heightOption: HeightType) => {
            return (
              <Dropdown.Item eventKey={heightOption.toString()}>
                {heightOption + '"'}
              </Dropdown.Item>
            );
          })}
        </DropdownButton>

        {drawLevels(height)}

        <Accordion className="mt-2">
          <Card>
            <Accordion.Toggle as={Card.Header} variant="link" eventKey="0">
              Which Height should I choose?
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="0">
              <Card.Body>
                <p>
                  <b>Shorter (12" and below)</b>
                  <ul>
                    <li>cheaper to build</li>
                    <li>needs less lumber and soil</li>
                    <li>
                      better choice for plants that grow tall like tomatoes or
                      pole beans
                    </li>
                    <li>
                      harder to garden; requires more bending and stooping
                    </li>
                  </ul>
                </p>
                <p>
                  <b>Taller (16" and above)</b>
                  <ul>
                    <li>more expensive to build</li>
                    <li>requires more lumber and soil</li>
                    <li>
                      good choice for shorter plants like bush beans or carrots
                    </li>
                    <li>
                      easier to garden; requires less bending and stooping
                    </li>
                  </ul>
                </p>
                <p>
                  <b>I still need help deciding.</b>
                  <br />
                  Choose something in the 18" - 24" range.
                </p>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        </Accordion>
        {displayButtons(Steps.LENGTH_WIDTH, Steps.TRANSPORTATION)}
      </div>
    );
  }

  function handleDimensionsStrategySelection(
    event: React.ChangeEvent<HTMLInputElement>
  ) {
    setUsePopularDimensions(!usePopularDimensions);
  }

  function displayCustomDimensions() {
    return (
      <div>
        <h5>Length</h5>
        <DropdownButton
          className="mb-2"
          variant="secondary"
          size="lg"
          id="length"
          title={length + " inches"}
          onSelect={(eventKey: any, event: Object) => {
            setLength(Number(eventKey) as LengthType);
          }}
        >
          {LengthOptions.map((lengthOption: LengthType) => {
            return (
              <Dropdown.Item eventKey={lengthOption.toString()}>
                {lengthOption + "inches"}
              </Dropdown.Item>
            );
          })}
        </DropdownButton>
        <h5>Width</h5>
        <DropdownButton
          className="mb-2"
          variant="secondary"
          size="lg"
          id="width"
          title={width + " inches"}
          onSelect={(eventKey: any, event: Object) => {
            setWidth(Number(eventKey) as WidthType);
          }}
        >
          {WidthOptions.map((widthOption: WidthType) => {
            return (
              <Dropdown.Item eventKey={widthOption.toString()}>
                {widthOption + "inches"}
              </Dropdown.Item>
            );
          })}
        </DropdownButton>
      </div>
    );
  }

  function displayPopularDimensions() {
    return (
      <div>
        <h5>Popular Dimensions</h5>
        <DropdownButton
          variant="secondary"
          size="lg"
          id="popular"
          title={length + " inches x " + width + " inches"}
          onSelect={(eventKey: any, event: Object) => {
            setHeight(Number(eventKey) as HeightType);
          }}
        >
          {HeightOptions.map((heightOption: HeightType) => {
            return (
              <Dropdown.Item eventKey={heightOption.toString()}>
                {heightOption + "inches"}
              </Dropdown.Item>
            );
          })}
        </DropdownButton>
      </div>
    );
  }

  function displayLengthWidth() {
    return (
      <div>
        <Form.Group>
          <Form.Check
            checked={usePopularDimensions}
            type="checkbox"
            label="Choose from popular dimensions"
            id="dimensionStrategyCustom"
            onChange={handleDimensionsStrategySelection}
            name="dimensionStrategyRadio"
          />
        </Form.Group>
        {usePopularDimensions
          ? displayPopularDimensions()
          : displayCustomDimensions()}
        {drawDimensions(length, width)}
        <Accordion>
          <Card>
            <Accordion.Toggle as={Card.Header} variant="link" eventKey="0">
              Rectangle or Square?
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="0">
              <Card.Body>
                <p>
                  Long rectangular raised garden beds are preferred for plants
                  that need to be put in rows such as beans and carrots. Square
                  raised garden beds are preferred for plants that need more
                  space to spread out such as cucumbers or squash.
                </p>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        </Accordion>
        {displayButtons(Steps.JOINERY, Steps.HEIGHT)}
      </div>
    );
  }

  function handleJoinerySelection(event: React.ChangeEvent<HTMLInputElement>) {
    setJoineryType(Number(event.target.value) as JoineryType);
  }

  function displayJoinery() {
    return (
      <div>
        <h5>How do you want to connect your boards at the corners?</h5>
        <Form.Group>
          <Form.Check
            checked={joineryType === JoineryType.TWO_BY_FOUR}
            type="radio"
            label="Using 2x4s"
            id="joinery2x4"
            value={JoineryType.TWO_BY_FOUR.valueOf()}
            onChange={handleJoinerySelection}
            name="joineryRadio"
          />
          <Form.Check
            checked={joineryType === JoineryType.FOUR_BY_FOUR}
            type="radio"
            label="Using 4x4s"
            id="joinery4x4"
            value={JoineryType.FOUR_BY_FOUR.valueOf()}
            onChange={handleJoinerySelection}
            name="joineryRadio"
          />
        </Form.Group>
        <Alert variant={"info"}>
          TIP: 4x4s are easier to work with and a better choice for beginners.
        </Alert>
        {displayButtons(Steps.QUANTITY, Steps.LENGTH_WIDTH)}
      </div>
    );
  }

  function displayQuantity() {
    return (
      <div>
        <h5>How Many Raised Beds Do You Want To Build?</h5>
        <DropdownButton
          variant="secondary"
          size="lg"
          id="quantity"
          title={quantity}
          onSelect={(eventKey: any, event: Object) => {
            setQuantity(Number(eventKey) as QuantityType);
          }}
        >
          {QuantityOptions.map((quantityOption: QuantityType) => {
            return (
              <Dropdown.Item eventKey={quantityOption.toString()}>
                {quantityOption}
              </Dropdown.Item>
            );
          })}
        </DropdownButton>
        {displayButtons(Steps.FINISH, Steps.JOINERY)}
      </div>
    );
  }

  function displayIntro() {
    return (
      <div>
        <p className="mt-n2">
          Simple, strong and affordable. Built using standard sized construction
          lumber found at any big box store. The lumber is untreated making "The
          Classic" especially suited for vegetable gardening.
        </p>
        {displayButtons(Steps.TRANSPORTATION)}
      </div>
    );
  }

  function displayError() {
    return <div>Error - invalid step.</div>;
  }

  function displayStep() {
    switch (step) {
      case Steps.INTRO:
        return displayIntro();
      case Steps.TRANSPORTATION:
        return displayTransportation();
      case Steps.HEIGHT:
        return displayHeight();
      case Steps.LENGTH_WIDTH:
        return displayLengthWidth();
      case Steps.JOINERY:
        return displayJoinery();
      case Steps.QUANTITY:
        return displayQuantity();
      default:
        return displayError();
    }
  }

  return (
    <Container>
      <Row>
        <Col className="d-block d-md-none" sm={12}>
          <Image
            className="mb-2"
            src={classicImg1}
            fluid
            alt="Classic Raised Garden Bed Newly Created"
          />
        </Col>
        <Col md={6} sm={12}>
          <h1>{classicStyleData.title}</h1>
          {displayStep()}
        </Col>
        <Col className="d-none d-md-block" md={6}>
          <Image
            src={classicImg1}
            fluid
            alt="Classic Raised Garden Bed Newly Created"
          />
        </Col>
      </Row>
    </Container>
  );
};

export default Classic;
