import React from "react";
import Container from "react-bootstrap/Container";

const SiteMap: React.FC = () => {
  return (
    <Container>
      <h1>Site Map</h1>
      <ul>
        <li>
          <a href="/">Home</a>
        </li>
        <li>
          <a href="/plans">Popular Raised Bed Plans</a>
        </li>
        <li>
          <a href="/resources">Resources</a>
        </li>
        <li>
          <a href="/affiliate">Affiliate Link Disclaimer</a>
        </li>
        <li>
          <a href="/about">About</a>
        </li>
        <li>
          <a href="/privacy">Privacy Policy</a>
        </li>
        <li>
          <a href="/terms">Terms and Conditions</a>
        </li>
        <li>
          <a href="/map">Site Map</a>
        </li>
      </ul>
    </Container>
  );
};

export default SiteMap;
