import React from "react";
import { Row, Col, Image } from "react-bootstrap";
import { createPrimaryButton } from "../../HelperComponents";

import classic1 from "../../../assets/images/stock/classic design raised garden bed.jpg";
import classic2 from "../../../assets/images/stock/classic design raised garden bed mom and son.jpg";

const Home: React.FC = () => {
  return (
    <div>
      <Row>
        <Col lg={5}>
          <Image fluid src={classic1} alt="Classic Raised Garden Bed Design" />
        </Col>
        <Col lg={7}>
          <h1 className="encouragement">hey gardener!</h1>
          <h4>Are you ready to build your own raised garden bed?</h4>
          <p>
            You can do it and we can help. We'll walk you through a series of
            questions to help you design your perfect raised garden bed.
          </p>
          {createPrimaryButton("Design", "/design/classic")}
        </Col>
      </Row>
      <br />
      <Row>
        <Col lg={7}>
          <h4>How It Works</h4>
          <p>
            It couldn't be easier! We ask you a few questions about what you
            want and a customized raised garden bed plan is generated just for
            you! The plan includes a list of materials needed, what cuts to
            make, and how to build your raised garden bed.
          </p>
        </Col>
        <Col lg={5}>
          <Image
            fluid
            src={classic2}
            alt="Classic Raised Garden Bed Design Mother And Son"
          />
        </Col>
      </Row>
    </div>
  );
};

export default Home;
