import {
  TransportationLengthType,
  HeightType,
  LengthType,
  WidthType,
  JoineryType,
  QuantityType,
} from "../components/Constants/Constants";

export class ClassicModel {
  public readonly transportationMax: TransportationLengthType;
  public readonly height: HeightType;
  public readonly length: LengthType;
  public readonly width: WidthType;
  public readonly joineryType: JoineryType;
  public readonly quantity: QuantityType;

  constructor(
    _transportationLength: TransportationLengthType,
    _height: HeightType,
    _length: LengthType,
    _width: WidthType,
    _joineryType: JoineryType,
    _quantity: QuantityType
  ) {
    this.transportationMax = _transportationLength;
    this.height = _height;
    this.length = _length;
    this.width = _width;
    this.joineryType = _joineryType;
    this.quantity = _quantity;
  }
}
